import styles from './Bubble.module.css'

const Bubble = () => {
    return ( 
        <div className= {styles.bubble}>
            {/* <h1>Abdullah Alsuqayh</h1> */}
            <h1>Good User Experience = Good App</h1>
        </div>
     );
}
 
export default Bubble;